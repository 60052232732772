import React from 'react';
import PropTypes from 'prop-types';

import { media } from '../../../../styles/media';
import styled from 'styled-components';
import Card from '../../../Card/Card';
import { Container } from '../../../Grid';

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: transform 350ms;
    background-image: linear-gradient(
        0deg,
        rgba(5, 66, 94, 0.94),
        rgba(5, 66, 94, 0.94)
      ),
      url(${props => props.backgroundMobile});

    ${media.tablet`
      background-image: linear-gradient(0deg, rgba(5, 66, 94, 0.94), rgba(5, 66, 94, 0.94)), url(${props =>
        props.background});
    `};
  }
`;

const VerticalLine = styled.div`
  position: absolute;
  width: 1px;
  height: 62px;
  z-index: 3;
  background-color: ${({ theme }) => theme.color.brand.lightGrey};
  top: 44px;
  left: 50%;
  right: 50%;
`;

const BlockWrapper = styled.div`
  padding: 75px 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PushShopping = ({
  title,
  children,
  link,
  label,
  backgroundMobile,
  background
}) => {
  const card = {
    title,
    description: children,
    ctaLeft: { label, url: link }
  };

  return (
    <Wrapper backgroundMobile={backgroundMobile} background={background}>
      <Container>
        <VerticalLine />
        <BlockWrapper>
          <Card maxWidth={420} card={card} />
        </BlockWrapper>
      </Container>
    </Wrapper>
  );
};

PushShopping.propTypes = {
  link: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string,
  backgroundMobile: PropTypes.string,
  background: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default PushShopping;
