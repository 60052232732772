import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';

/**
 * Animation made in Tympanus Codrops
 *
 * @see https://github.com/codrops/TextInputEffects/blob/master/css/set2.css#L70
 */

const Label = styled.label`
  font-family: ${props => props.theme.font.regular};
  font-size: ${props =>
    props.smallText ? '1rem' : props.theme.font.size.input} !important;

  font-style: italic;
  color: ${props => props.secondary && props.theme.color.brand.grey};

  float: right;
  width: 90%;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  user-select: none;

  position: absolute;
  top: 0.95em;
  left: 0;

  padding: 0em;
  pointer-events: none;

  transform-origin: 0 0;
  transition: transform 0.3s 0.1s, color 1s;
  transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
`;

const Span = styled.span`
  position: relative;
  display: block;
  width: 100%;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Svg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  fill: none;

  stroke: ${props =>
    props.secondary
      ? props.theme.color.brand.grey
      : 'rgba(255, 255, 255, 0.45)'};

  pointer-events: none;

  transition: transform 0.7s, stroke 0.7s;
  transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
`;

const Input = styled.input`
  font-family: ${props => props.theme.font.regular};
  font-size: ${props => props.theme.font.size.input};

  border: none;
  outline: none;

  height: ${props => props.theme.input.size}px;

  color: ${props => (props.disabled ? props.theme.color.grey : 'currentColor')};
  background-color: transparent;

  &::placeholder {
    color: currentColor;
  }

  &::focus {
    outline: none;
  }

  ::-ms-clear {
    display: none;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px ${props => props.theme.color.brand.primary}
      inset;
    -webkit-text-fill-color: ${props => props.theme.color.brand.white};
    color: ${props => props.theme.color.brand.white};
  }

  position: relative;
  display: block;
  float: right;

  -webkit-appearance: none; /* for box shadows to show on iOS */

  width: 100%;

  &:focus + ${Label} {
    transform: translate3d(0, -0.9em, 0) scale3d(0.75, 0.75, 1);
  }

  &:focus ~ ${Svg} {
    transform: translate3d(-66.6%, 0, 0);
  }
`;

const Wrapper = styled.span`
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  vertical-align: top;

  overflow: hidden;
  padding-top: 1em;

  ${props =>
    props.hasValue &&
    `
    & > label {
      transform: translate3d(0, -0.9em, 0) scale3d(0.75, 0.75, 1);
    }

    & > svg {
      transform: translate3d(-66.6%, 0, 0);
    }

  `};
`;

const Root = styled.div`
  width: 100%;
`;

class AnimatedInput extends PureComponent {
  state = {
    hasValue: false
  };

  componentDidMount() {
    const { focus } = this.props;
    if (focus) {
      this.input.focus();
    }
  }

  handleValue = () => {
    this.setState({ hasValue: !!this.input.value.length });
  };

  render() {
    const {
      name,
      type,
      label,
      onChange,
      value,
      disabled,
      smallText,
      secondary
    } = this.props;
    const { hasValue } = this.state;

    return (
      <Root>
        <Wrapper hasValue={disabled ? true : hasValue}>
          <Input
            ref={ref => (this.input = ref)}
            type={type}
            id={name}
            onFocus={this.handleValue}
            onBlur={this.handleValue}
            onChange={onChange}
            value={value}
            disabled={disabled}
          />
          <Label htmlFor={name} smallText={smallText} secondary={secondary}>
            <Span>{label}</Span>
          </Label>
          <Svg
            width="300%"
            height="100%"
            viewBox="0 0 1200 60"
            preserveAspectRatio="none"
            secondary={secondary}
          >
            <path d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0" />
          </Svg>
        </Wrapper>
      </Root>
    );
  }
}

AnimatedInput.defaultProps = {
  focus: false,
  secondary: false
};

AnimatedInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'email']),
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  focus: PropTypes.bool,
  smallText: PropTypes.bool,
  secondary: PropTypes.bool
};

export default AnimatedInput;
