import React from 'react';

import PropTypes from 'prop-types';

import { Root, Logo } from './styles';
import { ImageFit } from '../../Image';

class Brand extends React.PureComponent {
  render() {
    const { img, logo, zoom } = this.props;

    return (
      <Root>
        <ImageFit src={img} mask zoom={zoom} />
        <Logo src={logo} />
      </Root>
    );
  }
}

Brand.defaultProps = {
  zoom: false
};

Brand.propTypes = {
  img: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  zoom: PropTypes.bool
};

export default Brand;
