import gql from 'graphql-tag';

export default gql`
  mutation addNewsletterSubscriber($email: String) {
    addNewsletterSubscriber(input: { email: $email }) {
      id
      email
      token
      enabled
    }
  }
`;
