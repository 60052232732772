import styled from 'styled-components';

import { media } from '../../../../styles/media';

export const Root = styled.div``;

export const Label = styled.p`
  font-family: ${props => props.theme.font.bold};
  font-size: 1.6rem;

  color: ${props => props.theme.color.brown};

  margin: ${props => props.theme.layout.gutter * 0.75}px 0
    ${props => props.theme.layout.gutter / 4}px;

  ${media.tablet`
    font-size: 2rem;
  `};
`;

export const Link = styled.a``;
