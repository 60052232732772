import styled from 'styled-components';

import { media } from '../../../src/styles/media';

const Main = styled.main`
  position: relative;

  .category {
    margin-top: ${props => props.theme.layout.gutter}px;

    &:first-of-type {
      margin-top: ${props => props.theme.layout.gutter * 3}px;
    }
  }

  ${media.tablet`
    .category {
      margin-top: ${props => props.theme.layout.gutter * 3}px;

      &:first-of-type {
        margin-top: ${props => props.theme.layout.gutter * 6}px;
      }
  `};
`;

export default Main;
