import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { media } from '../../../../styles/media';
import Button from '../../../Button';
import Icon from '../../../Icon';
import { ImageFit } from '../../../Image';
import Badge from '../../../Badge';
import OpeningBadge from '../../../Badge/OpeningBadge';
import theme from '../../../../theme';
import Text from '../../../Typography/Text';

export const Root = styled.div`
  position: relative;

  display: flex;
  flex-direction: ${props =>
    props.position === 'left' ? 'column' : 'column-reverse'};
  height: 100%;

  flex: 1 1 auto;

  color: ${props => props.theme.color.darkGrey};

  text-align: center;

  background-color: ${props => props.theme.color.white};

  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  border-radius: 2px;

  ${media.tablet`
    flex-direction: row;
    align-items: start;
  `};
`;

const BadgeWrapper = styled.div`
  position: absolute;
  top: -50px;
  right: 0;
  z-index: 2;
`;

const WrapperImg = styled.div`
  position: relative;
  flex: 1 1 auto;

  width: 100%;

  ${media.tablet`
    width: 50%;
    align-self: stretch;

    > div,
    > div > div {
      height: 100%;
    }
  `};
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: ${props => props.contentPosition};
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;

  padding: 20px;

  > a,
  > button {
    margin-top: auto;
  }

  ${media.tablet`
    padding: 40px;
    width: 50%;
    align-items: ${props => props.contentPosition};
  `};
`;

const ContentWrapper = styled.div`
  text-align: left;

  p {
    margin-bottom: 0px;
  }
`;

class HorizontalCard extends React.PureComponent {
  render() {
    const {
      title,
      img,
      imgMobile,
      children,
      imgPosition,
      badge,
      badgeHours,
      contentPosition
    } = this.props;

    return (
      <Root position={imgPosition}>
        {imgPosition === 'left' && (
          <WrapperImg>
            <ImageFit src={imgMobile} srcDesktop={img} radial ratio={52} />
          </WrapperImg>
        )}
        <Wrapper contentPosition={contentPosition}>
          {badge && (
            <BadgeWrapper>
              <OpeningBadge hours={badgeHours} />
            </BadgeWrapper>
          )}
          <Text variant="heading2" as="h2" color={theme.color.brand.primary}>
            {title}
          </Text>
          <ContentWrapper>{children}</ContentWrapper>
        </Wrapper>
        {imgPosition === 'right' && (
          <WrapperImg>
            <ImageFit src={img} radial ratio={52} />
          </WrapperImg>
        )}
      </Root>
    );
  }
}

HorizontalCard.defaultProps = {
  badge: true,
  contentPosition: 'center'
};

HorizontalCard.propTypes = {
  imgPosition: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  badge: PropTypes.bool,
  badgeHours: PropTypes.object,
  img: PropTypes.string.isRequired,
  imgMobile: PropTypes.string,
  icon: PropTypes.string.isRequired,
  contentPosition: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default HorizontalCard;
