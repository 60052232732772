import styled from 'styled-components';

import { media } from '../../../styles/media';

const Header = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 110px;

  background-color: ${props => props.theme.color.darkGrey};

  ${props => props.info && `background-color: ${props.theme.color.secondary};`};
  ${props =>
    props.primary && `background-color: ${props.theme.color.primary};`};

  > h3 {
    margin-bottom: 0;
    letter-spacing: 0.15rem;
    z-index: 1;
  }

  > a,
  > button,
  > p {
    display: none;
  }

  > p {
    width: 100%;
  }

  ${media.desktop`
    align-items: flex-start;
    flex-shrink: 0;

    width: 450px; height: 100%;

    padding: ${props =>
      `${props.theme.layout.gutter * 2.25}px ${props.theme.layout.gutter *
        (props.inverse ? 5 : 2.25)}px ${props.theme.layout.gutter *
        2.25}px ${props.theme.layout.gutter * (props.inverse ? 2.25 : 5)}px`};

    > a, > button, > p {
      display: block;
    }

    > a, > button {
      min-width: 185px;
    }

    h3 {
      text-align: left;
    }
  `};
`;

export default Header;
