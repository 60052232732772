import PropTypes from 'prop-types';
import { graphql } from 'react-apollo';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Container } from '../Grid';
import Newsletter from './Newsletter/Newsletter';
import { Query } from 'react-apollo';
import createContext from '../../utils/format/createContext';
import getMenu from '../../apollo/graphql/navigation/getMenu';
import addNewsletterSubscriber from '../../apollo/graphql/newsletter/addNewsletterSubscriber';
import { translate } from 'react-i18next';
import Logo from './Logo';
import useMobile from '../../utils/useMobile';
import Nav from './Nav';
import getGhdSocialNetworks from '../../apollo/graphql/navigation/getGhdSocialNetworks';
import SocialNetworks from './SocialNetworks';

const Root = styled.footer`
  background-color: ${props => props.theme.color.brand.primary};
  position: relative;

  ${Container} {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: ${props => props.theme.layout.gutter * 2}px;
    padding-bottom: ${props => props.theme.layout.gutter * 2}px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: ${props => !props.isMobile && 'space-between'};

  width: 100%;
  gap: 16px;
`;

const Footer = ({ i18n, t, addNewsletterSubscriber }) => {
  const [newsletterError, setNewsletterError] = useState('');
  const [processing, setProcessing] = useState(false);
  const [tokenNewsletter, setTokenNewsletter] = useState('');

  const { isMobile } = useMobile();

  const handleNewSubscriber = async email => {
    setProcessing(true);

    if (email.trim() !== '') {
      const response = await addNewsletterSubscriber({
        variables: {
          email
        }
      });

      if (
        response.data &&
        response.data.addNewsletterSubscriber &&
        !response.data.addNewsletterSubscriber.enabled
      ) {
        setTokenNewsletter(response.data.addNewsletterSubscriber.token);
        return true;
      } else {
        setNewsletterError(t('common:newsletter.alerts.already_subscribe'));
        return false;
      }
    } else {
      setNewsletterError(t('common:newsletter.alerts.error'));
      return false;
    }
  };

  return (
    <Root>
      <Container>
        <Wrapper>
          <Logo />

          <Query
            query={getGhdSocialNetworks}
            context={createContext(i18n.language)}
          >
            {({ loading, error, data }) => {
              if (!loading && !error && data && data.getGhdSocialNetworks) {
                const { getGhdSocialNetworks } = data;

                return (
                  <SocialNetworks
                    socialNetworks={getGhdSocialNetworks}
                    isMobile={isMobile}
                  />
                );
              }
              return null;
            }}
          </Query>
          <Query
            query={getMenu}
            context={createContext(i18n.language)}
            variables={{ type: 'FOOTER' }}
          >
            {({ loading, error, data }) => {
              if (!loading && !error && data && data.getMenu) {
                const { getMenu } = data;

                return <Nav links={getMenu.linksDetail} isMobile={isMobile} />;
              }
              return null;
            }}
          </Query>
        </Wrapper>
      </Container>
    </Root>
  );
};

Footer.propTypes = {
  i18n: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  addNewsletterSubscriber: PropTypes.func,
  t: PropTypes.func
};

export default translate()(
  graphql(addNewsletterSubscriber, {
    name: 'addNewsletterSubscriber',
    options: ownProps => ({
      context: createContext(ownProps.i18n.language)
    })
  })(Footer)
);
