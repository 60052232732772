import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link } from '../../../../routes';
import routes from '../../../../conf/routes';
import { media } from '../../../styles/media';

import Button from './Button';
import { ImageFit } from '../../Image';

const Root = styled.div`
  position: relative;
  width: 100%;

  background-color: ${props => props.theme.color.white};

  ${media.tablet`
    display: flex;
    flex-direction: column;

    padding: ${props => props.theme.layout.gutter * 2.25}px;
  `};
`;

const Nav = styled.ul`
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${media.desktop`
    display: flex;
    margin-bottom: ${props => props.theme.layout.gutter * 1.25}px;
  `};
`;

const NavItem = styled.li``;

const SliderItems = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: all ease 0.3s;
  @media (max-width: 1023px) {
    opacity: 1 !important;
    transform: none !important;
    position: initial;
    top: initial;
    left: initial;
  }
`;

const Content = styled.div`
  padding: ${props => props.theme.layout.gutter * 1.25}px
    ${props => props.theme.layout.gutter * 2}px;

  > a,
  > button {
    min-width: auto;
  }

  ${media.tablet`
    > a, > button { display: none; }

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    height: 100%;

    padding: 0;
  `};
`;

const Wrapper = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  height: 100%;

  margin: 0 ${props => props.theme.layout.gutter / -2}px
    ${props => props.theme.layout.gutter * 0.75}px;

  ${media.tablet`
    width: 100%;
    margin-bottom: 0;
  `};

  @media (max-width: 1023px) {
    height: auto;
  }
`;

const Item = styled.li`
  display: block;

  width: 50%;
  flex-shrink: 0;

  padding: ${props => props.theme.layout.gutter / 2}px;

  ${media.tablet`
    width: calc(100% / 3);
    max-width: 250px;

    .image-fit--wrapper {
      padding-top: 55%;
    }
  `};
`;

class Filter extends PureComponent {
  constructor(props) {
    super(props);
    this.refsSlider = [];
    this.interval = null;
  }
  state = {
    activeId: 0,
    activeSlide: 0
  };

  componentDidMount() {
    this.autoSlider();
  }

  autoSlider() {
    if (window.innerWidth > 1023) {
      this.interval = setInterval(() => {
        if (this.refsSlider.length > 1 && this.refsSlider[1] !== null) {
          let length = this.refsSlider.length;
          let value = this.state.activeSlide;
          if (value < length - 1) {
            value += 1;
          } else {
            value = 0;
          }
          this.setState({
            activeSlide: value
          });
        } else {
          clearInterval(this.interval);
          this.interval = null;
        }
      }, 3000);
    }
  }

  handleNavClick = id => {
    this.setState({ activeId: id, activeSlide: 0, interval: null });
    clearInterval(this.interval);
    this.interval = null;
    this.autoSlider();
  };

  renderItem = (type, shop, key) => {
    const path =
      type === 'brands'
        ? this.props.t('common:brands.navigation.brands')
        : routes.generic.langs[this.props.i18n.language].split('/')[0];
    const suffix = type === 'brands' ? '' : '/';
    let Shop = shop.detail || shop;
    let ShopImg =
      shop.logoDesk ||
      (shop.blocks && shop.blocks.length > 0 && shop.blocks[0].logo_desktop);
    return Shop && ShopImg ? (
      <Item key={Shop.id} index={Shop.id}>
        <Link
          route={`/${this.props.i18n.language}/${path}/${Shop.slug}${suffix}`}
          passHref
        >
          <a>
            {ShopImg ? (
              <ImageFit src={ShopImg} alt={Shop.name} contain />
            ) : null}
          </a>
        </Link>
      </Item>
    ) : null;
  };

  renderItems = (type, items) => {
    return items && items.length > 0
      ? items.map((item, key) => this.renderItem(type, item, key))
      : null;
  };

  renderBlocks = (type, items) => {
    let arrayToRender = [[]];
    let index = 0;
    let stepIndex = 0;
    let arrayIndex = 0;
    if (items && items.length > 0) {
      items.forEach(element => {
        index++;
        stepIndex++;
        if (stepIndex === 7) {
          stepIndex = 0;
          arrayIndex++;
          arrayToRender.push([]);
        }
        arrayToRender[arrayIndex].push(element);
      });
      return arrayToRender.map((element, key) => (
        <SliderItems
          style={
            this.state.activeSlide === key
              ? { opacity: 1, transform: 'translateY(0px)' }
              : { opacity: 0, transform: 'translateY(0px)' }
          }
          className="sliderItems"
          ref={sliderItem => {
            this.refsSlider[key] = sliderItem;
          }}
          key={key}
        >
          {this.renderItems(type, element)}
        </SliderItems>
      ));
      // this.renderItems(type, items)
    }
  };

  renderNavItem = ({ label }, i) => {
    return (
      <NavItem key={i}>
        <Button
          index={i}
          onTabClick={this.handleNavClick}
          active={this.state.activeId === i}
        >
          {label}
        </Button>
      </NavItem>
    );
  };

  renderNavItems = items => {
    return items.map(this.renderNavItem);
  };

  render() {
    const { filters, children } = this.props;

    if (!filters) {
      return null;
    }
    const hasChildren = filters.children;
    const type = hasChildren ? 'brands' : 'others';
    const categories = hasChildren ? Object.values(filters.children) : null;
    const links = hasChildren ? categories[this.state.activeId].items : filters;
    const filter = hasChildren ? categories[this.state.activeId].label : null;
    return (
      <Root>
        {filter && <Nav>{this.renderNavItems(categories)}</Nav>}
        <Content ref="container">
          {/* <Wrapper>{this.renderItems(type, links)}</Wrapper> */}
          <Wrapper>{this.renderBlocks(type, links)}</Wrapper>
          {children}
        </Content>
      </Root>
    );
  }
}

Filter.propTypes = {
  filters: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default translate()(Filter);
