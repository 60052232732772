import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from '../../styles/media';

const Root = styled.div`
  ${media.desktop`
    display: none;
  `};
`;

const MobileOnly = ({ children }) => {
  return <Root>{children}</Root>;
};

MobileOnly.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default MobileOnly;
