import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { media } from '../../../styles/media';
import theme from '../../../theme';
import Button, { LinkBadge } from '../../Button';
import { ImageFit } from '../../Image';
import Text from '../../Typography/Text';

export const Root = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: start;
  flex: 1 1 auto;
  color: ${props => props.theme.color.darkGrey};
  text-align: center;
  background-color: ${props => props.theme.color.white};
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  border-radius: 2px;

  ${media.tablet`
    text-align: left;
  `};
`;

const WrapperImg = styled.div`
  position: relative;
  flex: 1 1 auto;

  width: 50%;
  align-self: stretch;

  > div,
  > div > div {
    height: 100%;
  }
`;

const Date = styled.span`
  font-size: 1.2rem;
  letter-spacing: 2px;
  text-transform: uppercase;

  ${media.tablet`
    font-family: ${props => props.theme.font.black};
    font-size: 1.4rem;
  `};
`;

const Title = styled.h4`
  font-size: 1.8rem;
  text-align: left;

  margin-top: ${props => props.theme.layout.gutter * 0.75}px;
  margin-bottom: ${props => props.theme.layout.gutter * 4}px;
  color: ${props => props.theme.color.darkBlue};

  ${media.tablet`
  font-family: ${props => props.theme.font.bold};
    font-size: 2.6rem;
    margin-bottom: 0;
    margin-top: ${props => props.theme.layout.gutter * 1.25}px;
  `};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1 1 auto;
  width: 50%;
  padding: ${props => props.theme.layout.gutter * 1.25}px;

  > a,
  > button {
    margin-top: auto;
  }

  ${media.tablet`
    align-items: flex-start;
  `};
`;

const Content = styled.div`
  width: 100%;
  text-align: left;
  margin-top: ${props => props.theme.layout.gutter / 1.75}px;
  margin-bottom: ${props => props.theme.layout.gutter / 1.75}px;
  display: none;

  p {
    font-size: 1.4rem;
    letter-spacing: 0.5px;
    line-height: 1.7rem;
    padding: 0 5px;
    text-align: center;

    color: ${props => props.theme.color.darkGrey};
  }

  ${media.tablet`
    display: block;
     p {
      font-size: 1.8rem;
      letter-spacing: 0.5px;
      line-height: 2.9rem;
      text-align: left;
    }
  `};
`;

class PressRelease extends React.PureComponent {
  render() {
    const { title, date, img, icon, children, link, linkLabel } = this.props;

    return (
      <Root>
        <WrapperImg>
          <ImageFit src={img} radial ratio={52} />
        </WrapperImg>
        <Wrapper>
          <Text variant="body" as="p">
            {date}
          </Text>
          <Text variant="heading3" as="p" color={theme.color.brand.primary}>
            {title}
          </Text>
          <Text variant="body2" as="p" marginBottom="5px" marginTop="5px">
            <div dangerouslySetInnerHTML={{ __html: children }} />
          </Text>
          {link && <Button href={link}>{linkLabel}</Button>}
        </Wrapper>
      </Root>
    );
  }
}

PressRelease.propTypes = {
  title: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  link: PropTypes.string,
  linkLabel: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default PressRelease;
