import React, { PureComponent } from 'react';
import { translate, Interpolate } from 'react-i18next';
import { CookieWrapper, CookieBanner, CookieClose } from './styles';
import routes from '../../../conf/routes';

class Cookies extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      cookie: props.cookieAccepted ? props.cookieAccepted : false
    };
  }

  componentDidMount() {
    if (!this.getCookie()) {
      this.setState({
        cookie: false
      });
    }
  }

  handleCookie = () => {
    this.setState({ cookie: true });

    this.setCookie('cookie', 'allowed');
  };

  setCookie = (name, value) => {
    let exdays = 30;
    let exdate = new Date();
    exdate.setDate(exdate.getDate() + parseInt(exdays));
    let cValue =
      encodeURI(value) +
      (exdays === null ? '' : '; expires=' + exdate.toUTCString() + ';path=/');
    document.cookie = name + '=' + cValue;
  };

  getCookie = () => {
    let cookieValue = document.cookie.match(/(;)?cookie=([^;]*);?/);

    if (cookieValue == null) {
      return undefined;
    } else {
      return decodeURI(cookieValue[2]);
    }
  };

  getText = () => {
    const { t, i18n } = this.props;
    return (
      <a href={`/${i18n.language}/${routes.confidentiality.langs[i18n.language]}/`}>{t('common:cookies.link')}</a>
    )
  }

  render() {
    const { cookie } = this.state;
    const { t, i18n } = this.props;
    return (
      <CookieWrapper className={cookie ? 'hide' : ''}>
        <CookieBanner>
          <Interpolate i18nKey='common:cookies.title' link_text={this.getText()} />
        </CookieBanner>
        <CookieClose onClick={() => this.handleCookie()} />
      </CookieWrapper>
    );
  }
}

Cookies.defaultProps = {
  cookieAccepted: true
};

export default translate()(Cookies);
