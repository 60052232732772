import React, { useState, useEffect } from 'react';
import { graphql } from 'react-apollo';
import createContext from '../../../utils/format/createContext';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { translate, Interpolate } from 'react-i18next';
import { FormInline, Checkbox } from '../../Form';
import NewsletterModal from './NewsletterModal';
import activateNewsletterSubscriber from '../../../apollo/graphql/newsletter/activateNewsletterSubscriber';
import Text from '../../Typography/Text';
import theme from '../../../theme';
import routes from '../../../../conf/routes';

const Root = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.isMobile ? 'center' : 'flex-start')};
  width: 100%;
  max-width: 300px;
  order: ${props => props.isMobile && '3'};
`;

const Label = styled.label`
  font-family: ${props => props.theme.font.brand.secondary};
  font-size: 18px;
  color: ${props => props.theme.color.brand.accent};
  margin-bottom: 20px;
`;

const StyledFormInline = styled(FormInline)`
  width: 100%;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-top: 20px;
  font-size: 14px;
  line-height: 18px;
  color: ${props => props.theme.color.brand.white};
  font-size: 11px;
`;

const StyledLink = styled.a`
  color: ${props => props.theme.color.brand.white};
  text-decoration: none;
`;

const Newsletter = ({
  onAddSubscriber,
  error,
  processing,
  tokenNewsletter,
  activateNewsletterSubscriber,
  t,
  isMobile,
  i18n
}) => {
  const [response, setResponse] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [openedModal, setOpenedModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkboxError, setCheckboxError] = useState('');

  useEffect(
    () => {
      if (error) {
        setResponse(error);
      }

      if (processing) {
        setDisabled(processing);
      }
    },
    [error, processing]
  );

  const toggleModal = bool => {
    setOpenedModal(bool);
  };

  const handleConfirmation = async () => {
    if (tokenNewsletter !== '') {
      const response = await activateNewsletterSubscriber({
        variables: {
          token: tokenNewsletter
        }
      });

      if (response.data && response.data.activateNewsletterSubscriber) {
        setResponse(t('common:newsletter.alerts.validate'));
        setDisabled(true);
        setOpenedModal(false);
      } else {
        setResponse(t('common:newsletter.alerts.error'));
        setDisabled(true);
        setOpenedModal(false);
      }
    }
  };

  const onCloseModal = () => {
    setResponse(t('common:newsletter.alerts.not_valid'));
    setDisabled(true);

    toggleModal(false);
  };

  const handleSubmit = async value => {
    if (checked) {
      if (await onAddSubscriber(value)) {
        toggleModal(true);
      }
    } else {
      setCheckboxError(t('common:newsletter.alerts.not_checked'));
    }
  };

  return (
    <Root isMobile={isMobile}>
      {/*
      <Label>{t('common:newsletter.title')}</Label>

      <StyledFormInline
        label={t('common:newsletter.form.email.placeholder')}
        icon="arrowTopRight"
        light
        name="newsletter"
        inputType="email"
        onSubmit={handleSubmit}
        value={response}
        disabled={disabled}
      />

      {checkboxError && (
        <Text
          variant="body2"
          as="p"
          color={theme.color.brand.accent}
          marginTop="10px"
        >
          {checkboxError}
        </Text>
      )}

      <StyledCheckbox
        name="newslette-optin"
        onChange={() => {
          setCheckboxError('');
          setChecked(!checked);
        }}
      >
        <Interpolate
          i18nKey="common:newsletter.checkbox_label"
          privacyPolicy={
            <StyledLink
              href={`/${i18n.language}/${
                routes.confidentiality.langs[i18n.language]
              }`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('common:newsletter.privacy_policy')}
            </StyledLink>
          }
        />
      </StyledCheckbox>

      <NewsletterModal
        opened={openedModal}
        onClose={onCloseModal}
        handleConfirmation={handleConfirmation}
      />*/}
    </Root>
  );
};

Newsletter.propTypes = {
  i18n: PropTypes.object,
  onAddSubscriber: PropTypes.func,
  error: PropTypes.bool,
  processing: PropTypes.bool,
  tokenNewsletter: PropTypes.string,
  activateNewsletterSubscriber: PropTypes.func,
  t: PropTypes.func,
  isMobile: PropTypes.bool
};

export default translate()(
  graphql(activateNewsletterSubscriber, {
    name: 'activateNewsletterSubscriber',
    options: ownProps => ({
      context: createContext(ownProps.i18n.language)
    })
  })(Newsletter)
);
