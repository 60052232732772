import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../Button';
import { media } from '../../styles/media';

const Wrapper = styled.div`
  max-width: ${({ maxWidth }) => maxWidth || 305}px;
  min-width: ${({ minWidth }) => minWidth || 305}px;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.color.brand.white};
  padding: 40px;
  position: relative;
  height: ${({ fullHeight }) => (fullHeight ? '100%' : 'auto')};
`;

const Title = styled.h2`
  font-family: ${({ theme }) => theme.font.brand.secondary};
  color: ${({ theme }) => theme.color.brand.primary};
  font-size: ${({ bigTitle }) => (bigTitle ? '36px' : '22px')};
  line-height: 30.8px;
`;

const Description = styled.p`
  font-family: ${({ theme }) => theme.font.brand.primary};
  color: ${({ theme }) => theme.color.brand.grey};
  font-size: 16px;
  line-height: 22.4px;
  margin: 20px 0;
  a {
    color: ${props => props.theme.color.brand.primary};
  }
  a:visited {
    color: ${props => props.theme.color.brand.primary};
  }
`;

const BadgeImg = styled.img`
  position: absolute;
  top: -35px;
  right: -30px;
  width: ${({ width }) => width || 106}px;
  height: ${({ height }) => height || 106}px;
  object-fit: cover;
`;

const ButtonsWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;

  ${media.tablet`
    flex-direction: row;
    align-items: center;
  `};
`;

const StyledButton = styled(Button)`
  margin-bottom: 20px;

  ${media.tablet`
    margin-bottom: 0px;
    margin-right: 60px;
  `};
`;

const Card = ({
  card,
  maxWidth,
  minWidth,
  fullHeight,
  backgroundColor,
  bigTitle
}) => {
  return (
    <Wrapper
      maxWidth={maxWidth}
      minWidth={minWidth}
      backgroundColor={backgroundColor}
      fullHeight={fullHeight}
    >
      {card?.badgeImage?.url && (
        <BadgeImg
          src={card.badgeImage.url}
          width={card?.badgeImage?.width}
          height={card?.badgeImage?.height}
          alt="Badge Card"
        />
      )}
      <Title bigTitle={bigTitle}>{card?.title}</Title>
      <Description>{card?.description}</Description>
      <ButtonsWrapper>
        {card?.ctaLeft?.label && (
          <StyledButton
            iconType={card?.ctaLeft?.iconType}
            href={card?.ctaLeft?.url}
          >
            {card?.ctaLeft?.label}
          </StyledButton>
        )}
        {card?.ctaRight?.label && (
          <Button
            iconType={card?.ctaRight?.iconType}
            href={card?.ctaRight?.url}
          >
            {card?.ctaRight?.label}
          </Button>
        )}
      </ButtonsWrapper>
    </Wrapper>
  );
};

Card.propTypes = {
  card: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    badgeImage: PropTypes.shape({
      url: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number
    }),
    ctaLeft: PropTypes.shape({
      label: PropTypes.string,
      iconUrl: PropTypes.string,
      iconType: PropTypes.string,
      url: PropTypes.string
    }),
    ctaRight: PropTypes.shape({
      label: PropTypes.string,
      iconUrl: PropTypes.string,
      iconType: PropTypes.string,
      url: PropTypes.string
    })
  }),
  maxWidth: PropTypes.number,
  minWidth: PropTypes.number,
  fullHeight: PropTypes.bool,
  backgroundColor: PropTypes.string,
  titleAs: PropTypes.string,
  bigTitle: PropTypes.bool
};

export default Card;
