import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { media } from '../../../styles/media';
import Button from '../../Button';
import Icon from '../../Icon';
import { ImageFit } from '../../Image';

import VideoCard from './VideoCard';
import HorizontalCard from './HorizontalCard';
import ParkingCard from './ParkingCard';

export const Root = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  min-height: 100%;

  flex: 1 1 auto;

  color: ${props => props.theme.color.darkGrey};

  text-align: center;

  background-color: ${props => props.theme.color.white};

  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  border-radius: 2px;
`;

const Title = styled.h4`
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  align-items: center;

  font-family: ${props => props.theme.font.bold};
  font-size: 2.6rem;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: ${props => props.theme.color.black};

  > i {
    position: relative;
    top: -3px;

    font-size: 3rem;

    margin-right: ${props => props.theme.layout.gutter / 4}px;
  }

  ${media.tablet`
    font-size: 2.8rem;
    letter-spacing: 2px;

    margin-top: ${props => props.theme.layout.gutter / 2}px;

    > i {
      position: relative;
      top: -5px;

      font-size: 3.5rem;
    }
  `};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;

  padding: ${props => props.theme.layout.gutter * 1.25}px;

  > a,
  > button {
    margin-top: auto;
  }

  ${media.tablet`
    padding-bottom: ${props => props.theme.layout.gutter * 2.25}px;
  `};
`;

const Content = styled.div`
  width: 100%;
  margin-top: ${props => props.theme.layout.gutter * 0.75}px;

  ${media.tablet`
    margin-top: ${props => props.theme.layout.gutter * 1.25}px;
    text-align: left;
  `};
`;

class Card extends React.PureComponent {
  render() {
    const { title, img, link, linkLabel, icon, children } = this.props;

    return (
      <Root>
        <ImageFit src={img} radial ratio={52} />
        <Wrapper>
          <Title>
            <Icon name={icon} />
            {title}
          </Title>
          <Content>{children}</Content>
          {link && <Button href={link}>{linkLabel}</Button>}
        </Wrapper>
      </Root>
    );
  }
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  link: PropTypes.string,
  linkLabel: PropTypes.string,
  icon: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export { Card, VideoCard, HorizontalCard, ParkingCard };
export default Card;
