import styled from 'styled-components';

import Header from './Header';
import Filter from './Filter';

import { media } from '../../../styles/media';

const ShopSign = styled.div`
  position: relative;

  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  color: ${props => props.theme.color.darkGrey};

  background-color: ${props => props.theme.color.white};

  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  border-radius: 2px;

  z-index: 0;

  .parallax {
    position: absolute !important;
    top: 50%;
    left: 0;

    height: 240px !important;

    transform: translate3d(0, -55%, 0);
    pointer-events: none;

    [class^='parallax-banner-layer'] {
      width: 120px;
      right: auto !important;
      background-size: contain !important;
      background-repeat: no-repeat;
    }
  }

  .parallax_under {
    z-index: -1;
  }

  .parallax_shop {
    .parallax-banner-layer-0 {
      display: none;
    }

    .parallax-banner-layer-1 {
      left: -40px !important;
      background-position: left 85% !important;
    }
  }

  .parallax_shop_under {
    z-index: 0;

    .parallax-banner-layer-0 {
      background-position: left 35% !important;
    }

    .parallax-banner-layer-1 {
      display: none;
    }
  }

  .parallax_restaurant {
    .parallax-banner-layer-0 {
      left: -15px !important;
      background-position: left 70% !important;
    }

    .parallax-banner-layer-1 {
      display: none;
    }
  }

  .parallax_restaurant_under {
    z-index: 1;

    .parallax-banner-layer-0 {
      left: -50px !important;
      background-position: left 35% !important;
    }
  }

  .parallax_other {
    .parallax-banner-layer-0 {
      left: -20px !important;
      background-position: left center !important;
    }

    .parallax-banner-layer-1 {
      display: none;
    }
  }

  .parallax_other_under {
    .parallax-banner-layer-0 {
      width: 80px;

      left: 40px !important;
      right: auto !important;
      background-position: right 35% !important;
    }
  }

  ${media.desktop`
    flex-direction: ${props => (props.inverse ? 'row-reverse' : 'row')};
    height: 450px;

    .parallax {
      width: 450px;
      height: 750px !important;

      [class^='parallax-banner-layer'] {
        width: 185px;
      }
    }

    .parallax_shop {
      .parallax-banner-layer-0 {
        display: block;
        left: -20px !important;
        background-position: left 10% !important
      }

      .parallax-banner-layer-1 {
        left: 100px !important;
        background-position: left 24% !important;
      }
    }

    .parallax_shop_under {
      z-index: -1;
      top: 30%;

      .parallax-banner-layer-0 {
        left: 180px !important;
        background-position: left 20% !important;
      }

      .parallax-banner-layer-1 {
        display: block;
        width: 80px;

        left: 150px !important;
        background-position: left 16% !important;
      }
    }

    .parallax_restaurant, .parallax_restaurant_under {
      [class^='parallax-banner-layer'] {
        left: auto !important;
      }
    }

    .parallax_restaurant {
      .parallax-banner-layer-0 {
        width: 220px;

        right: -110px !important;
        background-position: right 27% !important;
      }

      .parallax-banner-layer-1 {
        display: block;

        right: -70px !important;
        background-position: right 65% !important;
      }
    }

    .parallax_restaurant_under {
      z-index: -1;

      .parallax-banner-layer-0 {
        right: 10px !important;
        background-position: right 20% !important;
      }
    }

    .parallax_other {
      .parallax-banner-layer-0 {
        width: 300px;
        left: -120px !important;
        background-position: left 20% !important;
      }

      .parallax-banner-layer-1 {
        display: block;

        left: 175px !important;
        width: 100px;

        background-position: left 35% !important;
      }
    }

    .parallax_other_under {
      .parallax-banner-layer-0 {
        width: 200px;

        right: auto !important;
        left: 60px !important;
        background-position: left 25% !important;
      }
    }
  `};
`;

export { Header, Filter };

export default ShopSign;
