import gql from 'graphql-tag';

export default gql`
  query getGhdSocialNetworks {
    getGhdSocialNetworks {
      id
      name
      url
      icon_light
      icon_dark
    }
  }
`;
