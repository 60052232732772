import React from 'react';
import styled from 'styled-components';
import { translate } from 'react-i18next';
import { Link } from '../../../routes';
import PropTypes from 'prop-types';

import { media } from '../../styles/media';

const Root = styled.a``;

const Img = styled.img`
  display: block;
  width: 110px;
  height: auto;

  ${media.desktop`
    width: 150px;
  `};
`;

const Logo = ({ i18n }) => {
  return (
    <Link route={`/${i18n.language}/`} passHref>
      <Root>
        <Img src="/static/images/logo.svg" alt="Logo Grand Hôtel-Dieu" />
      </Root>
    </Link>
  );
};

Logo.propTypes = {
  i18n: PropTypes.object.isRequired
};

export default translate()(Logo);
