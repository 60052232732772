import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Column, Row } from '../../../Grid';
import CardWithImageInArc from '../../../Card/CardWithImageInArc';
import useMobile from '../../../../utils/useMobile';
import styled from 'styled-components';

const Root = styled.div`
  ${Column} {
    padding-left: ${props => props.theme.layout.gutter / 2}px;
    padding-right: ${props => props.theme.layout.gutter / 2}px;
  }
`;

const BrandList = ({ items, t }) => {
  const { isMobile } = useMobile();

  const renderItem = (shop, index) => {
    const card = {
      title: shop.name,
      image: {
        url: shop.imgDesk,
        alt: 'Image shop',
        width: isMobile ? 162 : 260,
        height: isMobile ? 162 : 260
      },
      logo: {
        url: shop.logo2Desk,
        alt: 'Logo shop'
      },
      link: `${t('common:brands.navigation.brands')}/${shop?.type}/${shop
        ?.category?.detail?.slug || 'uncategorized'}/${shop.slug}`
    };

    return (
      <Column key={shop.id} mobile={2} tablet={3} desktop={4}>
        <CardWithImageInArc
          card={card}
          maxWidth={isMobile ? 162 : 260}
          minWidth={isMobile ? 162 : 260}
          fullHeight
          isNew={shop.is_new}
          isHeart={shop.is_heart}
        />
      </Column>
    );
  };

  const renderList = () => {
    return items.map(renderItem);
  };

  return (
    <Root>
      <Row space={10}>{renderList()}</Row>
    </Root>
  );
};

BrandList.propTypes = {
  items: PropTypes.array.isRequired,
  t: PropTypes.func
};

export default translate()(BrandList);
