import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Column, Row } from '../../Grid';
import Article from './index';
import routes from '../../../../conf/routes';

const Root = styled.div`
  ${Column} {
    padding-left: ${props => props.theme.layout.gutter / 2}px;
    padding-right: ${props => props.theme.layout.gutter / 2}px;
  }
`;

const ArticleList = ({ articles, i18n, t }) => {
  return (
    <Root>
      <Row space={10}>
        {articles.map((article, index) => (
          <Column key={index} mobile={2} tablet={3} desktop={3}>
            <Article
              key={index}
              img={article.img_featured_desktop}
              title={article.detail.title}
              tags={
                article.detail.tags.trim !== ''
                  ? article.detail.tags.split(',')
                  : []
              }
              link={
                article.type === 'internal'
                  ? `/${i18n.language}/${t('common:brands.navigation.blog')}/${
                      article.detail.slug
                    }`
                  : article.link
              }
              type={article.type}
              zoom
            />
          </Column>
        ))}
      </Row>
    </Root>
  );
};

ArticleList.propTypes = {
  articles: PropTypes.array
};

export default ArticleList;
