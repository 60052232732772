import PropTypes from 'prop-types';
import React from 'react';
import { ImageFit } from '../../Image';
import { Information, Root, Tag, Title } from './style';
import { Link } from '../../../../routes';

const Article = ({ img, zoom, title, tags, link, type }) => {
  const children =
    type === 'internal' ? (
      <Link route={link} passHref>
        <a>
          <ImageFit src={img} mask zoom={zoom} />
          <Information>
            <Title>{title}</Title>
            {tags.map((tag, index) => (
              <Tag key={index}>{`#${tag} `}</Tag>
            ))}
          </Information>
        </a>
      </Link>
    ) : (
      <a href={link} target="_blank">
        <ImageFit src={img} mask zoom={zoom} />
        <Information>
          <Title>{title}</Title>
          {tags.map((tag, index) => (
            <Tag key={index}>{`#${tag} `}</Tag>
          ))}
        </Information>
      </a>
    );
  return <Root>{children}</Root>;
};

Article.defaultProps = {
  zoom: false
};

Article.propTypes = {
  img: PropTypes.string.isRequired,
  tags: PropTypes.array,
  title: PropTypes.string.isRequired,
  zoom: PropTypes.bool,
  link: PropTypes.string,
  type: PropTypes.string
};

export default Article;
