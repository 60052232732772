import styled from 'styled-components';
import { media } from '../../styles/media';

export const CookieWrapper = styled.div`
  position: fixed;
  background: white;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.25);

  &.hide {
    display: none;
  }

  ${media.tablet`
    bottom: 0;
  `};

  .cookie-message {
    display: block;

    color: #4a4a4a;
    font-size: 1.2rem;
    line-height: 1.8rem;

    max-width: 790px;
    margin: 0 auto;
    text-align: center;

    ${media.tablet`
      font-size: 2rem;
      line-height: 2.9rem;
    `};
  }

  .react-cookie-banner {
    padding: 21px 30px;
  }

  .button-close {
    position: absolute;
    top: 10px;
    right: 10px;

    display: block;

    width: 20px;
    height: 20px;

    background: none;
    border: none;
    padding: 0;

    cursor: pointer;
    z-index: 101;

    ${media.tablet`
      right: 50px;
      top: 42px;
    `} &::after,
    &::before {
      content: '';
      position: absolute;
      top: 0;
      width: 1px;
      height: 20px;
      background: #2d3155;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
`;

export const CookieBanner = styled.div`
  display: block;
  padding: 21px 30px;

  color: #4a4a4a;
  font-size: 1.2rem;
  line-height: 1.8rem;

  max-width: 780px;
  margin: 0 auto;

  ${media.tablet`
    font-size: 2rem;
    line-height: 2.9rem;
  `};

  a {
    color: inherit;
  }
`;

export const CookieClose = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;

  display: block;

  width: 20px;
  height: 20px;

  background: none;
  border: none;
  padding: 0;

  cursor: pointer;

  ${media.tablet`
    right: 50px;
    top: 42px;
  `} &::after,
  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: 1px;
    height: 20px;
    background: #2d3155;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;
