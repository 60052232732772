import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Link } from '../../../../../routes';

import { media } from '../../../../styles/media';
import Icon from '../../../Icon';
import Text from '../../../Typography/Text';
import theme from '../../../../theme';

export const Root = styled.div`
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;

  flex: 1 1 auto;

  color: ${props => props.theme.color.darkGrey};

  text-align: center;

  border: 1px solid #d9dcde;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  text-align: left;
  padding: 20px;

  > a,
  > button {
    margin-top: auto;
  }
`;

const Content = styled.div`
  width: 100%;
  margin-top: 10px;
  text-align: left;
  p {
    margin-bottom: 0px;
    font-size: ${props => props.theme.font.body2.fontSize};
    line-height: ${props => props.theme.font.body2.lineHeight};
  }
`;

const Simpleink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 20px ${props => props.theme.layout.gutter * 1.25}px;
  text-align: left;

  color: ${props => props.theme.color.primary};

  font-size: 1.6rem;
  letter-spacing: 0.13rem;
  line-height: 1;

  text-decoration: none;

  background: transparent;
  border: none;
  outline: none;
  border-top: 1px solid ${props => props.theme.color.lightGrey};

  cursor: pointer;
`;

class ParkingCard extends React.PureComponent {
  render() {
    const { title, link, linkLabel, children } = this.props;

    return (
      <Root>
        <Wrapper>
          <Text variant="heading3" as="h3" color={theme.color.brand.accent}>
            {title}
          </Text>
          <Content>{children}</Content>
        </Wrapper>
        {link && (
          <Link route={link} passHref>
            <Simpleink>
              {linkLabel}
              <Icon name="long-arrow-right" />
            </Simpleink>
          </Link>
        )}
      </Root>
    );
  }
}

ParkingCard.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  linkLabel: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default ParkingCard;
