import styled from 'styled-components';

const Label = styled.label`
  display: block;
  width: 100%;

  margin-bottom: ${props => props.theme.layout.gutter}px;

  font-family: ${props => props.theme.font.black};
  font-size: 1.1rem;
  letter-spacing: 0.25rem;
  text-align: center;

  color: ${props => props.theme.color.white};
`;

export default Label;
