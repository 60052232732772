import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// import { media } from '../../../styles/media';

const Root = styled.button`
  font-family: ${props => props.theme.font.black};
  font-size: 1.3rem;
  letter-spacing: 1px;
  line-height: 1.5rem;
  text-transform: uppercase;

  padding: ${props => props.theme.layout.gutter}px;

  color: ${props =>
    props.active ? props.theme.color.primary : props.theme.color.black};
  background: transparent;
  border: none;
  outline: none;

  cursor: pointer;

  transition: all 0.2s ease-in-out;
`;

class Button extends PureComponent {
  handleClick = () => {
    this.props.onTabClick(this.props.index);
  };

  render() {
    const { active, children } = this.props;

    return (
      <Root active={active} onClick={this.handleClick}>
        {children}
      </Root>
    );
  }
}

Button.defaultPropTypes = {
  isActive: false
};

Button.propTypes = {
  index: PropTypes.number.isRequired,
  active: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  onTabClick: PropTypes.func.isRequired
};

export default Button;
