import styled from 'styled-components';
import { media } from '../../../styles/media';

export const Root = styled.div`
  position: relative;
`;

export const Title = styled.p`
  color: ${props => props.theme.color.white};
  font-family: ${props => props.theme.font.bold};
  font-size: 1.8rem;
  margin-bottom: 5px;

  ${media.tablet`
    font-size: 2.6rem;
  `};
`;

export const Information = styled.div`
  padding: 0 15px 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  ${media.tablet`
    padding: 0 30px 2s5px;
  `};
`;

export const Tag = styled.span`
  color: ${props => props.theme.color.white};
  font-family: ${props => props.theme.font.medium};
  font-size: 1.4rem;

  ${media.tablet`
    font-size: 2rem;
  `};
`;
