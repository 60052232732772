import React, { PureComponent } from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';

import theme from '../../theme';
import Cookies from '../Cookies';
import Footer from '../Footer';

class Page extends PureComponent {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <div>
          <Cookies />
          {this.props.children}
          <Footer />
        </div>
      </ThemeProvider>
    );
  }
}

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default Page;
