import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import YouTube from 'react-youtube';

import { media } from '../../../../styles/media';
import Button from '../../../Button';
import Icon from '../../../Icon';
import Text from '../../../Typography/Text';
import theme from '../../../../theme';

export const Root = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  height: 100%;

  flex: 1 1 auto;

  color: ${props => props.theme.color.darkGrey};

  text-align: center;

  background-color: ${props => props.theme.color.white};

  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  border-radius: 2px;

  ${media.tablet`
    flex-direction: row;
  `};
`;

const Title = styled.h2`
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  align-items: center;

  font-family: ${props => props.theme.font.bold};
  font-size: 2.6rem;
  font-size: 3rem;
  letter-spacing: 0;

  color: ${props => props.theme.color.brown};

  > i {
    position: relative;
    top: -3px;

    font-size: 3rem;

    margin-right: ${props => props.theme.layout.gutter / 4}px;
  }

  ${media.tablet`
    font-size: 3.5rem;
    line-height: 4rem;

    margin-top: ${props => props.theme.layout.gutter / 2}px;

    > i {
      position: relative;
      top: -5px;

      font-size: 3.5rem;
    }
  `};
`;

const WrapperVideo = styled.div`
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  position: relative;
  min-height: 400px;

  > div {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.theme.color.brand.primary};
    background-image: ${props => `url(${props.background})`};
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    &.hide {
      display: none;
    }

    i {
      font-size: 3rem;
      color: #fff;
      top: -4px;
      position: relative;
    }

    div {
      font-size: 1.6rem;
      text-transform: uppercase;
      color: ${props => props.theme.color.white};

      &:before {
        content: '|';
        top: -2px;
        position: relative;
        margin-left: 4px;
        margin-right: 4px;
      }
    }
  }

  > span {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    display: block;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  ${media.tablet`
    width: 55%;
    align-self: stretch;

    > div {
      div {
        font-size: 2.8rem;
      }
    }
  `};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  flex: 1 1 auto;
  width: 100%;
  text-align: left;

  padding: ${props => props.theme.layout.gutter * 2}px;

  > a,
  > button {
    margin-top: auto;
  }

  p {
    font-size: 1.8rem;
    margin-bottom: ${props => props.theme.layout.gutter * 2}px;
  }

  ${media.tablet`
    width: 45%;
    align-self: stretch;

    padding: ${props => props.theme.layout.gutter * 4}px ${props =>
    props.theme.layout.gutter * 7}px;

    p {
      font-size: 1.8rem;
      line-height: 2.5rem;
    }
  `};
`;

const Content = styled.div`
  width: 100%;
  margin-top: ${props => props.theme.layout.gutter * 0.75}px;

  ${media.tablet`
    margin-top: ${props => props.theme.layout.gutter * 1.25}px;
    text-align: left;
  `};
`;

class VideoCard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.playerEvent = null;
    this.opts = {
      height: '90%',
      width: '50%',
      playerVars: {
        autoplay: 0,
        rel: 0
      }
    };

    this.state = {
      showOverlay: true
    };

    this.playVideo = this.playVideo.bind(this);
    this._onReady = this._onReady.bind(this);
  }

  render() {
    const {
      title,
      videoSrc,
      link,
      linkLabel,
      children,
      background,
      t
    } = this.props;
    const { showOverlay } = this.state;

    return (
      <Root>
        <WrapperVideo background={background}>
          <div
            className={showOverlay ? '' : 'hide'}
            style={{ zIndex: 10 }}
            onClick={this.playVideo}
          >
            <Icon name="video" />
            <div>{t('common:place.video')}</div>
          </div>
          <YouTube
            videoId={videoSrc}
            opts={this.opts}
            onReady={this._onReady}
          />
        </WrapperVideo>
        <Wrapper>
          <Text as="h3" color={theme.color.brand.primary} variant="heading2">
            {title}
          </Text>
          <Content>
            <Text variant="body" as="p" color={theme.color.brand.grey}>
              {children}
            </Text>
          </Content>
          {link && <Button href={link}>{linkLabel}</Button>}
        </Wrapper>
      </Root>
    );
  }

  playVideo() {
    this.setState({
      showOverlay: false
    });
    this.playerEvent.target.playVideo();
  }

  _onReady(event) {
    this.playerEvent = event;
  }
}

VideoCard.propTypes = {
  title: PropTypes.string.isRequired,
  videoSrc: PropTypes.string.isRequired,
  link: PropTypes.string,
  linkLabel: PropTypes.string,
  background: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default translate()(VideoCard);
