import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { Link } from '../../../../routes';
import { ImageFit } from '../../Image';

const Root = styled.a`
  text-decoration: none;
  display: block;
`;

class Figure extends PureComponent {
  render() {
    const { title, img, link, type } = this.props;

    return type === 'internal' ? (
      <Link route={link} passHref>
        <Root title={title}>
          <ImageFit src={img} />
        </Root>
      </Link>
    ) : (
      <a href={link} target="_blank">
        <Root title={title}>
          <ImageFit src={img} />
        </Root>
      </a>
    );
  }
}

Figure.propTypes = {
  title: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  type: PropTypes.string
};

export default Figure;
