import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from '../../styles/media';

const Root = styled.div`
  display: none;

  ${media.desktop`
    display: block;
  `};
`;

const DesktopOnly = ({ children }) => {
  return <Root>{children}</Root>;
};

DesktopOnly.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default DesktopOnly;
