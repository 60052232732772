import React from 'react';
import styled from 'styled-components';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import Link from '../Link/Link';

const Root = styled.nav``;

const LinksContainer = styled.ul`
  display: flex;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  justify-content: center;
  align-items: center;
`;

const LinkContainer = styled.li`
  margin-bottom: ${props => props.isMobile && '8px'};

  margin-left: ${props => !props.isMobile && '20px'};
  &:first-child {
    margin-left: ${props => !props.isMobile && '0px'};
  }

  a {
    font-family: ${props => props.theme.font.brand.secondary};
    font-size: 14px;
    color: ${props => props.theme.color.brand.white};
    cursor: pointer;
    text-decoration: none;

    transition: opacity 0.3s ease-out;

    &:hover {
      opacity: 0.7;
    }
  }
`;

const Nav = ({ links, isMobile }) => {
  return (
    <Root>
      <LinksContainer isMobile={isMobile}>
        {links.map(link => {
          return (
            <LinkContainer key={link.id} isMobile={isMobile}>
              <Link href={link.url}>{link.label}</Link>
            </LinkContainer>
          );
        })}
      </LinksContainer>
    </Root>
  );
};

Nav.propTypes = {
  links: PropTypes.array,
  isMobile: PropTypes.bool
};

export default translate()(Nav);
