import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { ButtonSubmit } from './../Button';
import IconSVG from './../Icon/IconSVG';
import Input from './Input';
import color from '../../theme/color';

const Root = styled.form``;

const InputWrapper = styled.div`
  position: relative;
  color: ${props =>
    props.light ? props.theme.color.white : props.theme.color.black};
`;

const Button = styled(ButtonSubmit)`
  position: absolute;
  top: 60%;
  right: 0;

  transform: translate3d(0, -50%, 0);
  transition: right 0.2s ease-in-out;

  z-index: 1;

  > .icon {
    font-size: 1.5rem;
  }
`;

class FormInline extends PureComponent {
  state = {
    value: ''
  };

  handleChange = event => {
    this.setState({ value: event.target.value });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.onSubmit(this.state.value);
  };

  static getDerivedStateFromProps(props) {
    const { value } = props;

    if (value && value.length > 0) {
      return {
        value
      };
    }

    // Return null to indicate no change to state.
    return null;
  }

  render() {
    const {
      label,
      name,
      inputType,
      placeholder,
      light,
      icon,
      disabled,
      smallText,
      iconColor
    } = this.props;
    const { value } = this.state;

    return (
      <Root onSubmit={this.handleSubmit} className={this.props.className}>
        <InputWrapper light={light}>
          <Input
            type={inputType}
            label={label}
            placeholder={placeholder}
            name={name}
            id={name}
            value={value}
            onChange={this.handleChange}
            disabled={disabled}
            smallText={smallText}
            secondary={!light}
            iconColor={iconColor}
          />
          <Button>
            <IconSVG
              name={icon}
              color={iconColor ? iconColor : color.brand.accent}
            />
          </Button>
        </InputWrapper>
      </Root>
    );
  }
}

FormInline.defaultProps = {
  light: false,
  inputType: 'text',
  smallText: false
};

FormInline.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  light: PropTypes.bool,
  inputType: PropTypes.oneOf(['text', 'email']),
  value: PropTypes.string,
  disabled: PropTypes.bool,
  smallText: PropTypes.bool,
  iconColor: PropTypes.string,
  className: PropTypes.string
};

export default FormInline;
