import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link as NextLink } from '../../../../../routes';

import { Root, Label, Link } from './styles';
import Brand from '../Brand';

class LinkBrand extends PureComponent {
  render() {
    const { link, img, logo, label } = this.props;

    return (
      <Root>
        <NextLink route={link} passHref>
          <Link>
            <Brand img={img} logo={logo} zoom={true} />
          </Link>
        </NextLink>
        <Label>{label}</Label>
      </Root>
    );
  }
}

LinkBrand.propTypes = {
  link: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default LinkBrand;
