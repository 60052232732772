import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { media } from '../../styles/media';
import color from '../../theme/color';

import Icon from '../Icon';

const Root = styled.div`
  width: 100%;
  text-align: left;

  ${media.desktop`
    display: inline-block;
    max-width: 50%;
    text-align: center;
  `};
`;

const LabelWrapper = styled.span`
  position: relative;
  display: block;
  margin-bottom: ${props => props.theme.layout.gutter / 3}px;
  text-align: left;

  ${media.desktop`
    display: inline-block;
    margin: 0 ${props => props.theme.layout.gutter}px;
  `};
`;

const Label = styled.label`
  display: inline-block;
  padding: 0 0 0 ${props => props.theme.layout.gutter / 3}px;
  font-family: ${props => props.theme.font.medium};
  font-size: 15px;
  color: ${props => props.theme.color.darkGrey};

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  user-select: none;
  pointer-events: none;
`;

const InputWrapper = styled.span`
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: 215px;
  cursor: pointer;

  ${media.desktop`
    max-width: 180px;
  `};
`;

const LabelIcon = styled(Icon)`
  font-size: 16px;
  margin-right: ${props => props.theme.layout.gutter / 4}px;
`;

const InputSelect = styled.select`
  display: block;
  padding: 5px 12px;
  margin: 0 auto 15px;
  width: 100%;
  height: 28px;
  font-family: ${props => props.theme.font.medium};
  font-size: 13px;
  color: ${props => props.theme.color.darkGrey};
  border: 1px solid ${props => props.theme.color.lightGrey};
  border-radius: 28px;
  overflow: hidden;

  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;

  &::placeholder {
    color: ${props => props.theme.color.darkGrey};
  }

  &::focus {
    outline: none;
  }

  ::-ms-clear {
    display: none;
  }

  ${media.desktop`
    margin: 0;
  `};
`;

const Svg = styled.svg`
  position: absolute;
  top: 10px;
  right: 12px;
  fill: none;
  transition: transform 0.7s cubic-bezier(0, 0.25, 0.5, 1);
`;

class Select extends PureComponent {
  state = {
    hasValue: false
  };

  renderOptions = (item, index) => {
    const disabled = item.disabled ? true : false;
    return (
      <option key={index} value={item.name} disabled={disabled}>
        {item.name}
      </option>
    );
  };

  render() {
    const { name, label, icon, onChange, value, data } = this.props;

    return (
      <Root>
        <LabelWrapper>
          {icon && <LabelIcon name={icon} color={color.primary} />}
          <Label htmlFor={name}>{label}</Label>
        </LabelWrapper>
        <InputWrapper>
          <InputSelect
            ref={ref => (this.select = ref)}
            id={name}
            onChange={onChange}
            value={value}
          >
            {data.map(this.renderOptions)}
          </InputSelect>
          <Svg
            width="11px"
            height="8px"
            viewBox="0 0 23 16"
            preserveAspectRatio="none"
          >
            <polyline
              stroke={color.primary}
              strokeWidth="5.76000031"
              transform="translate(11.324971, 2.324971) scale(-1, 1) rotate(135.000000) translate(-11.324971, -2.324971) "
              points="4.71782782 -4.25538647 17.9321135 -4.25538647 17.9321135 8.90532782"
            />
          </Svg>
        </InputWrapper>
      </Root>
    );
  }
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  data: PropTypes.array.isRequired
};

export default Select;
