import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from '../../../../routes';

import { media } from '../../../styles/media';

import Time from '../../Time';
import { ImageFit } from '../../Image';

const Root = styled.a`
  position: relative;

  display: block;
  text-decoration: none;

  margin-bottom: ${45 / 2}px;
`;

const Title = styled.h3`
  position: absolute;
  left: ${({ theme }) => theme.layout.gutter * 1.25}px;
  bottom: ${({ theme }) => theme.layout.gutter * 2}px;

  font-family: ${props => props.theme.font.bold};

  font-size: 2.6rem;
  line-height: 3rem;

  width: 60%;

  color: ${props => props.theme.color.white};
  pointer-events: none;
`;

const TimeWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;

  transform: translate3d(0, 50%, 0);
`;
const ImageWrapper = styled.div`
  width: ${props => (props.responsive ? '100%' : '280px')};
  height: 410px;
  overflow: hidden;

  ${media.desktop`
    width: 350px;
    height: 479px;
  `};
`;

class Event extends PureComponent {
  render() {
    const { title, img, link, date, responsive } = this.props;

    return (
      <Link route={link} passHref>
        <Root title={title}>
          <ImageWrapper responsive={responsive}>
            <ImageFit src={img} gradient ratio={150} zoom />
          </ImageWrapper>
          <Title>{title}</Title>
          <TimeWrapper>
            <Time date={date} />
          </TimeWrapper>
        </Root>
      </Link>
    );
  }
}

Event.defaultProps = {
  responsive: false
};

Event.propTypes = {
  title: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  date: PropTypes.string,
  responsive: PropTypes.bool
};

export default Event;
