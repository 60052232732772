import React from 'react';
import styled from 'styled-components';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';

import { media } from '../../../styles/media';

import Brand from '../Brand';
import { Logo } from '../Brand/styles';
import { Button } from '../../Button';
import Time from '../../Time';

const Inner = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
`;
const BrandWrapper = styled.div`
  position: relative;
  overflow: hidden;
  flex: 0 0 auto;
  transition: height 0.35s;

  > div {
    height: 100%;
  }

  &:after {
    content: '';
    display: block;
    margin-bottom: 0%;
    transition: margin-bottom 0.35s;
  }
`;

const ButtonWrapper = styled.div`
  opacity: 0;
  height: 0;
  transition: opacity 0.35s, height 0.35s;
`;

const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  position: relative;

  padding: ${props => props.theme.layout.gutter}px
    ${props => props.theme.layout.gutter}px
    ${props => props.theme.layout.gutter * 1.25}px;
`;

const H3 = styled.h3`
  flex: 0 0 auto;
  font-family: ${props => props.theme.font.bold};
  font-size: 2.4rem;
  line-height: 2.7rem;

  color: ${props => props.theme.color.brown};

  margin-bottom: ${props => props.theme.layout.gutter / 2}px;

  ${media.desktop`
    font-size: 3.2rem;
    line-height: 3.6rem;
  `};
`;

const P = styled.p`
  height: 100%;
  overflow: hidden;

  font-family: ${props => props.theme.font.regular};
  font-size: 1.3rem;
  line-height: 2.15rem;
  color: ${props => props.theme.color.darkGrey};
  letter-spacing: 0.04rem;
  margin-bottom: ${props => props.theme.layout.gutter / 2}px;

  max-height: 160px;

  ${media.desktop`
    max-height: none;
    font-size: 1.8rem;
    line-height: 2.9rem;
    letter-spacing: 0.5px;
 `};
`;

const TimeWrapper = styled.div`
  position: absolute;
  left: 0;
  top: -45px;
`;

const Root = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;

  color: ${props => props.theme.color.darkGrey};

  background-color: ${props => props.theme.color.white};

  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  border-radius: 2px;

  ${media.desktop`
    height: 514px;
    width: 330px;

    &:hover {
      ${BrandWrapper} {
        &:after {
          margin-bottom: -62%;
        }
      }

      ${ButtonWrapper} {
        opacity: 1;
        height: 50px;
        flex-shrink: 0;
      }

      ${P} {
        margin-bottom: ${props => props.theme.layout.gutter * 2}px;
      }

      ${Logo} {
        opacity: 0;
      }
  `};
`;

class Offer extends React.PureComponent {
  render() {
    const { title, img, logo, date, text, link, hideButton, t } = this.props;

    return (
      <Root>
        <BrandWrapper>
          <Brand img={img} logo={logo} />
        </BrandWrapper>
        <Container>
          <H3>{title}</H3>
          <Inner>
            <P>{text}</P>
            {!hideButton && (
              <ButtonWrapper>
                <Button href={link}>{t('common:brands.cta.offers')}</Button>
              </ButtonWrapper>
            )}
          </Inner>
          <TimeWrapper>
            <Time date={date} />
          </TimeWrapper>
        </Container>
      </Root>
    );
  }
}

Offer.defaultProps = {
  hideButton: false
};

Offer.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  linkLabel: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  date: PropTypes.string,
  text: PropTypes.string.isRequired,
  hideButton: PropTypes.bool
};

export default translate()(Offer);
