import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { media } from '../../../styles/media';
import Button from '../../Button';
import { translate } from 'react-i18next';
import Text from '../../Typography/Text';
import theme from '../../../theme';

const Logo = styled.img`
  height: 100%;
`;

const transitionFadeIn = fadeDuration => `opacity ${fadeDuration}s ease-in`;
const transitionFadeOut = fadeDuration =>
  `visibility 0s ${fadeDuration}s, opacity ${fadeDuration}s ease-in`;

const Wrapper = styled.div`
  visibility: ${props => (props.opened ? 'visible' : 'hidden')};
  transition: ${props =>
    props.opened
      ? transitionFadeIn(props.fadeDuration)
      : transitionFadeOut(props.fadeDuration)};
  opacity: ${props => (props.opened ? 1 : 0)};
  position: relative;
  z-index: 999;
`;

const Shadow = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  background-color: ${({ theme }) => theme.color.shadowModal};
  opacity: 0.7;
`;

const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 4;
  width: calc(100vw - 50px);
  transform: translate(-50%, -50%);
  background-color: white;
  text-align: center;

  ${media.tablet`
    width: calc(100vw - 100px);
  `};

  ${media.desktop`
    width: 30vw;
  `};
`;

const HeaderModal = styled.div`
  width: 100%;
  height: 96px;
  padding: 15px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.color.brand.primary};
`;

const ModalContent = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${media.tablet`
      padding: 37px 72px;
  `};
`;

const NewsletterModal = ({ opened, onClose, handleConfirmation, t }) => {
  return (
    <Wrapper opened={opened} fadeDuration={0.3}>
      <Shadow onClick={onClose} />
      <Container>
        <HeaderModal>
          <Logo src="/static/images/logo.svg" alt="Logo Grand Hôtel-Dieu" />
        </HeaderModal>
        <ModalContent>
          <Text
            variant="heading3"
            marginBottom="20px"
            color={theme.color.brand.grey}
            align="center"
          >
            {t('newsletter.modal.title')}
          </Text>

          <Text
            variant="body1"
            marginBottom="30px"
            color={theme.color.brand.primary}
            align="center"
          >
            {t('newsletter.modal.content')}
          </Text>

          <Button onClick={handleConfirmation} iconType="arrowBottomRight">
            {t('newsletter.modal.button')}
          </Button>
        </ModalContent>
      </Container>
    </Wrapper>
  );
};

NewsletterModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleConfirmation: PropTypes.func.isRequired,
  t: PropTypes.func
};

export default translate()(NewsletterModal);
