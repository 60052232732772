import styled from 'styled-components';

export const Root = styled.div`
  position: relative;
`;

export const Logo = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;

  max-width: 65%;

  transform: translate3d(-50%, -50%, 0);
  transition: opacity 0.35s;

  pointer-events: none;
`;
