import styled from 'styled-components';

export const Root = styled.div`
  font-family: ${props => props.theme.font.bold};
  font-size: 1.2rem;
  letter-spacing: 0.3px;
  text-transform: uppercase;

  height: 30px;
  line-height: 32px;

  color: ${props => props.theme.color.darkGrey};
  background: ${props => props.theme.color.white};

  border: 1px solid #eee;

  border-radius: 30px;

  padding: 0 ${props => props.theme.layout.gutter / 2}px;

  &:before {
    display: inline-block;
    vertical-align: sub;
    
    content: '';

    width: 12px;
    height: 12px;

    margin-right: ${props => props.theme.layout.gutter / 4}px;

    background: ${props =>
      props.open ? props.theme.color.green : props.theme.color.primary};

    border-radius 50%;
  }
`;
