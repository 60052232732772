import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import styled from 'styled-components';
import Link from '../Link/Link';

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    margin-left: ${props => (props.isMobile ? '18px' : '8px')};
    width: ${props => (props.isMobile ? '25px' : '20px')};
    height: ${props => (props.isMobile ? '25px' : '20px')};

    &:first-of-type {
      margin-left: 0px;
    }
  }
`;

const Icon = styled.img`
  width: 100%;
  height: 100%;

  transition: opacity 0.1s ease-out;

  &:hover {
    opacity: 0.7;
  }
`;

const SocialNetworks = ({ isMobile, socialNetworks }) => {
  return (
    <Root isMobile={isMobile}>
      {socialNetworks.map(socialNetwork => {
        const { id, name, url, icon_light } = socialNetwork;
        return (
          <Link key={id} href={url}>
            <Icon src={icon_light} alt={name} />
          </Link>
        );
      })}
    </Root>
  );
};

SocialNetworks.propTypes = {
  isMobile: PropTypes.bool,
  socialNetworks: PropTypes.array
};

export default translate()(SocialNetworks);
