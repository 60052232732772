import gql from 'graphql-tag';

export default gql`
  query getMenu($type: String!) {
    getMenu(type: $type) {
      categoriesDetail {
        id
        order
        name
        linksDetail {
          id
          url
          label
        }
      }

      linksDetail {
        id
        url
        label
      }
    }
  }
`;
