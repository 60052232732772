import React, { PureComponent } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';

import moment from 'moment-timezone';

import { Root } from './styles';

class Badge extends PureComponent {
  checkTime(hours) {
    if (!hours) return false;

    // Current Day
    const _date = moment().tz('Europe/Paris');
    const currentDay = _date.format('dddd').toLowerCase();
    const hours_min = parseFloat(_date.hours() + ',' + _date.minute());

    // Past Day
    const _datePast = moment()
      .tz('Europe/Paris')
      .subtract(1, 'days');
    const pastDay = _datePast.format('dddd').toLowerCase();

    if (hours[currentDay]) {
      const open = parseFloat(hours[currentDay].open.replace(':', '.'));
      const close = parseFloat(hours[currentDay].close.replace(':', '.'));

      if (open && close && open <= hours_min) {
        if (close >= hours_min || close < open) {
          return true;
        }
      }
    }

    if (hours[pastDay]) {
      const open = parseFloat(hours[pastDay].open.replace(':', '.'));
      const close = parseFloat(hours[pastDay].close.replace(':', '.'));
      if (
        open &&
        close &&
        hours[pastDay].close < hours[pastDay].open &&
        hours[pastDay].close >= hours_min
      ) {
        return true;
      }
    }

    return false;
  }

  render() {
    const { hours, t } = this.props;
    const open = this.checkTime(hours);

    return (
      <Root open={open}>
        {open ? t('common:schedule.open') : t('common:schedule.close')}
      </Root>
    );
  }
}

Badge.defaultProps = {
  hours: {
    monday: {
      open: '10:00',
      close: '19:30'
    },
    tuesday: {
      open: '10:00',
      close: '19:30'
    },
    wednesday: {
      open: '10:00',
      close: '19:30'
    },
    thursday: {
      open: '10:00',
      close: '19:30'
    },
    friday: {
      open: '10:00',
      close: '19:30'
    },
    saturday: {
      open: '10:00',
      close: '19:30'
    }
  }
};

Badge.propTypes = {
  hours: PropTypes.object
};

export default translate()(Badge);
