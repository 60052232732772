import PropTypes from 'prop-types';
import React from 'react';

import PushShopping from './PushShopping';

const Push = ({ link, label, background, backgroundMobile }) =>
  backgroundMobile &&
  background &&
  label &&
  link && (
    <PushShopping
      label={label}
      link={link}
      background={background}
      backgroundMobile={backgroundMobile}
    />
  );

Push.propTypes = {
  link: PropTypes.string,
  label: PropTypes.string,
  background: PropTypes.string,
  backgroundMobile: PropTypes.string
};

export { Push, PushShopping };
export default Push;
