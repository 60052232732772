import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Root = styled.time`
  display: block;
  padding: 0 ${props => props.theme.layout.gutter}px;

  height: 45px;

  font-family: ${props => props.theme.font.black};
  font-size: 1.5rem;
  letter-spacing: 0.2rem;
  line-height: 5rem;

  text-align: center;
  text-transform: uppercase;

  color: ${props =>
    props.icon ? props.theme.color.brown : props.theme.color.white};
  background-color: ${props =>
    props.info ? props.theme.color.secondary : props.theme.color.primary};

  white-space: nowrap;
`;

class Time extends React.PureComponent {
  render() {
    const { startDate, endDate, date } = this.props;

    return <Root>{date ? date : `DU ${startDate} AU ${endDate}`}</Root>;
  }
}

Time.propTypes = {
  date: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string
};

export default Time;
