import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const Root = styled.div``;

const Label = styled.label`
  position: relative;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: baseline;

  &:before {
    content: '';
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
    max-width: 12px;
    max-height: 12px;

    margin-right: ${props => props.theme.layout.gutter * 0.375}px;

    background-color: ${props => props.theme.color.darkWhite};

    transition: all 0.2s ease-in-out;
  }
`;

const Input = styled.input`
  width: 0;
  height: 0;
  display: none;

  &:checked + ${Label} {
    &:before {
      background-color: ${props => props.theme.color.primary};
      box-shadow: inset 0 0 0 3px ${props => props.theme.color.darkWhite};
    }
  }
`;

class Checkbox extends PureComponent {
  handleChange = () => {
    this.props.onChange(this.props.name, this.props.category);
  };

  render() {
    const { name, checked, children, className } = this.props;

    return (
      <Root className={className}>
        <Input
          id={name}
          name={name}
          type="checkbox"
          checked={checked}
          value={name}
          onChange={this.handleChange}
        />
        <Label htmlFor={name}>{children}</Label>
      </Root>
    );
  }
}

Checkbox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  checked: PropTypes.bool.isRequired,
  name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  category: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string
};

export default Checkbox;
